.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: var(--background);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 94vh;
  padding: 20px;
  background: var(--background);

  strong {
    color: var(--text-secondary);
  }

  b{
    font-weight: 400;
  }

  h1 {
    color: var(--text-primary);
  }
}

.container-rastreio{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.input {
  width: 18rem;
  height: 50px;
  border-radius: 5px;
  border: 1px solid var(--text-secondary);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 0 20px;
  text-transform: uppercase;
  background: var(--background-content);
  color: var(--text-primary);

  &:focus {
    outline: none;
  }
}

.button-rastreio {
  width: 4rem;
  height: 50px;
  border-radius: 5px;
  border: 1px solid var(--text-secondary);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background: var(--text-secondary);
  color: var(--text-primary-btn);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;


  &:hover {
    transition: 0.4s;
    filter: brightness(80%);
  }
}

.container-evento {
  display: flex;
  background: var(--background-content);
  border-radius: 5px;
  box-shadow: var(--lm-shadow-active);
  margin-top: 14px;
  margin-bottom: 1rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    color: var(--text-primary)
  }
}

.container-info {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;

}

.container-descricao {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  span {
    color: var(--text-primary);
    font-weight: bold;
    font-size: 1rem;
    margin-left: 1rem;
  }
}

.container-icon{
  display: flex;
  background: darkcyan;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: auto;
  margin-left: 1rem;
  width: 2rem !important;
  height: 1.75rem !important;
}

.container-local {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;

  span {
    font-size: 0.7rem;
  }
}

.container-status{
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;

  span {
    font-size: 0.6rem;
  }
}

.button-save {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 10;
  width: 60px;
  height: 60px;
  background: var(--text-secondary);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;

  &:hover {
    transition: 0.4s;
    filter: brightness(80%);
  }

}

@media screen and (min-width: 839px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: var(--background);
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 94vh;
    padding: 20px;
    background: var(--background);
  }
}
