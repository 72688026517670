.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 94vh;
  padding: 20px;
  background: var(--background);

  strong {
    color: var(--text-secondary);
  }

  b {
    font-weight: 400;
  }

  h1 {
    color: var(--text-primary);
  }
}

.container-rastreio{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.input {
  width: 18rem;
  height: 50px;
  border-radius: 5px;
  border: 1px solid var(--text-secondary);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 0 20px;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
}

.button-rastreio {
  width: 4rem;
  height: 50px;
  border-radius: 5px;
  border: 1px solid var(--text-secondary);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background: var(--text-secondary);
  color: var(--text-primary-btn);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
    transition: 0.4s;
  }
}

.container-cep {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background: var(--background);
  box-shadow: rgb(0 0 0 / 18%) 0px 2px 12px;
  padding: 2rem 3rem;
  width: 22rem;
  border-radius: 0.8rem;

  strong, p {
    color: var(--text-primary);
  }
}

.container-cep-info {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 0.5rem;

  strong {
    color: var(--text-primary) !important;
  }
}

@media screen and (min-width: 839px) {
 .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
 }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 94vh;
    padding: 20px;
    background: var(--background);
  }
}
