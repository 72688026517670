.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: var(--background);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 94vh;
  padding: 20px;
  background: var(--background);

  strong {
    color: var(--text-secondary);
  }

  b{
    font-weight: 400;
  }

  h1 {
    color: var(--text-primary);
  }
}

.container-rastreio {
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.rastreio-info {
  display: flex;
  width: 18rem;
  height: 50px;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 18%) 0px 2px 12px;
  color: var(--text-primary-btn);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;

  strong {
    color: var(--text-primary);
  }
}

@media screen and (min-width: 839px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: var(--background);
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 94vh;
    padding: 20px;
    background: var(--background);
  }
}
