.header {
  display: flex;
  background: var(--header);
  width: 100%;
  height: 3.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}

.logo {
  cursor: pointer;
}

strong {
  cursor: pointer;
  color: var(--text-primary);

  &:hover {
    color: var(--text-secondary);
    transition: 0.4s;
  }
}

.container-left {
  display: flex;
  align-items: center;
  width: 10rem;
  justify-content: space-between;
}

.mode {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 15rem;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: var(--text-secondary);
  margin-right: 1rem;
}

.icon {
  background: var(--background-content);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: var(--text-secondary);
    transition: 0.4s;
  }
}
