.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: var(--background);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 94vh;
  padding: 20px;
  background: var(--background);

  strong {
    color: var(--text-secondary);
  }

  b {
    font-weight: 400;
  }

  h1 {
    color: var(--text-primary);
  }
}

.container-login {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 15rem;
}

.input-login {
  width: 18rem;
  height: 50px;
  border-radius: 5px;
  border: 1px solid var(--text-secondary);
  padding: 0 20px;
  background: var(--background-content);
  color: var(--text-primary);

  &:focus {
    outline: none;
  }
}

.button-login {
  width: 18rem;
  height: 50px;
  border-radius: 5px;
  border: 1px solid var(--text-secondary);
  background: var(--text-secondary);
  color: var(--text-primary-btn);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    transition: 0.4s;
    filter: brightness(80%);
  }
}

.container-evento {
  display: flex;
  background: var(--background-content);
  border-radius: 5px;
  box-shadow: var(--lm-shadow-active);
  margin-top: 14px;
  margin-bottom: 1rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    color: var(--text-primary);
  }
}

.container-info {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
}

.container-descricao {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  span {
    color: var(--text-primary);
    font-weight: bold;
    font-size: 1rem;
    margin-left: 1rem;
  }
}

.container-icon {
  display: flex;
  background: darkcyan;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: auto;
  margin-left: 1rem;
  width: 2rem !important;
  height: 1.75rem !important;
}

.container-local {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;

  span {
    font-size: 0.7rem;
  }
}

.space {
  margin-top: -20px;
}

.container-status {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;

  span {
    font-size: 0.6rem;
  }
}

.google-btn {
  width: 18rem;
  height: 42px;
  background-color: #fff;
  border-radius: 3px;
  margin-top: 16px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;

  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    background-color: #fff;
  }
  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 11px;
    margin-left: 11px;
    color: #3c4043;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    box-shadow: 0 0 3px #4285f4;
  }
  &:active {
    background: #fff;
  }
}

.github-btn {
  width: 18rem;
  height: 42px;
  background-color: #252a2d;
  border-radius: 3px;
  margin-top: 16px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;

  .github-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    background-color: #252a2d;
  }
  .github-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 11px;
    margin-left: 11px;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    box-shadow: 0 0 3px #252a2d;
  }
  &:active {
    background: #252a2d;
  }
}

.separator {
  font-size: 14px;
  color: var(--text-primary);

  margin: 10px 0;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    flex: 1;
    height: 1px;
    background: var(--text-primary);
    margin-right: 16px;
    width: 7rem;
  }

  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background: var(--text-primary);
    margin-left: 16px;
    width: 7rem;
  }
}

@media screen and (min-width: 839px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: var(--background);
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 94vh;
    padding: 20px;
    background: var(--background);
  }
}
